<template>
  <div class="calendar">
    <!-- 日历头部 -->
    <div class="calendar_top">
      <span class="arrow_left" @click="changeMonth('reduce')"></span>
      <span class="calendar_top_date">{{
        date["year"] + "-" + date["month"]
      }}</span>
      <span class="arrow_right" @click="changeMonth('add')"></span>
    </div>
    <!-- 周期 -->
    <div class="calendar_week">
      <span v-for="(item, index) in week" :key="index">{{ item.text }}</span>
    </div>
    <div class="calendar_days">
      <div class="calendar_days_item" v-for="(item, index) in monthDays" :key="index"
        :style="`margin-left:${item.left}%`">
        <span class="calendar_days_item_num" @click="selectCurrentDay(item)" :style="`background:${currentSelectDay == item.day ? statusColor[item.status] : '#a5907e'
          };color:${currentSelectDay == item.day
            ? '#ffffff'
            : item.isGone
              ? '#C5B7AC'
              : '#ffffff'
          }`">
          {{ item.day }}
        </span>
        <span class="calendar_days_item_icondrop" v-if="item.status != 4 && currentSelectDay != item.day"
          :style="`background:${statusColor[item.status]};`"></span>
      </div>
    </div>
    <div class="calendar_types">
      <span class="calendar_type" v-for="(item, index) in types" :key="index">
        <span class="icondrop" :style="`background:${item.color};`"></span>
        <span class="calendar_type_text">{{ lang[item.text] }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { week, types, statusColor } from "./const";
import { getMonthDays, getWeekNum, setMonthDays } from "./calendar";
import Common from "@/library/Common";
import request from "@/library/MyRequest";
export default {
  props: {
    loading: Boolean
  },
  data() {
    return {
      week: week,
      types: types,
      statusColor: statusColor,
      currentSelectDay: 0,
      monthDays: [], //月份的天数
      date: {
        year: 0, //年
        month: 0, //月份
        day: 0, //日
      },
      lang: {
        status_course: "",
        status_SignInReview: "",
        status_reviewed: "",
      },
      weekNum: 0,
    };
  },
  created() {
    this.getNowMonth();
    this.initLanguage();
  },
  mounted() {
    this.watchLanguage();
  },
  methods: {
    initLanguage() {
      const common = Common;
      common.initLanguage(this.lang);
    },
    watchLanguage() {
      let _this = this;
      window.addEventListener("setItemEvent", function (e) {
        if (e.key === "Language") {
          setTimeout(() => {
            _this.initLanguage();
          }, 300);
        }
      });
    },

    changeMonth(type) {
      // if (this.loading) return;
      switch (type) {
        case "add":
          this.setMonth(
            this.date["month"] == 12
              ? this.date["year"] + 1
              : this.date["year"],
            this.date["month"] == 12 ? 1 : this.date["month"] + 1,
            // this.date["day"]
            1
          );
          break;
        case "reduce":
          this.setMonth(
            this.date["month"] == 1 ? this.date["year"] - 1 : this.date["year"],
            this.date["month"] == 1 ? 12 : this.date["month"] - 1,
            // this.date["day"]
            1
          );
          break;
      }
    },
    // 选择当前日期
    selectCurrentDay(data) {
      // if (!data.canSelect) return;
      // if (this.loading) return;
      this.currentSelectDay = data.day;
      console.log("selectCurrentDay", data);
      this.date = {
        ...this.date,
        year: data.data.year,
        month: data.data.month,
        day: data.data.day,
      };
      this.$emit("getLessonList", data.date);
    },
    // 获取当前月份和天数
    getNowMonth() {
      let curDate = new Date();
      // 获取当前月份
      let curYear = curDate.getFullYear();
      let curMonth = curDate.getMonth();
      let curDay = curDate.getDate();

      this.setMonth(curYear, curMonth + 1, curDay);
    },
    // 设置日历月份
    async setMonth(year, month, day) {
      this.date = {
        ...this.date,
        year,
        month,
        day,
      };
      const res = await request.get(`/lesson/month-sum/?month=${year}-${month}`);

      let lessonsList = res.data.lessons;
      let lessonsObjs = {};
      lessonsList.map((item) => {
        if (lessonsObjs[item["date"]]) {
          lessonsObjs[item["date"]].push(item["lesson_status"]);
        } else {
          lessonsObjs[item["date"]] = [item["lesson_status"]];
        }
      });
      let currentMonthDays = await getMonthDays(year, month);
      let weekNum = await getWeekNum(year, month);
      this.weekNum = weekNum;
      this.monthDays = setMonthDays(year, month, currentMonthDays, weekNum);
      console.log("this.monthDays", this.monthDays);
      this.monthDays.map((dayItem) => {
        if (lessonsObjs[dayItem["date"]]) {
          console.log('dayItem', dayItem);
          let status = lessonsObjs[dayItem["date"]].includes(2)
            ? 2
            : lessonsObjs[dayItem["date"]].includes(1)
              ? dayItem['isGone'] ? 2 : 1
              : 3;
          dayItem["status"] = status;
        }
      });
      this.currentSelectDay = day;

      if (day == 1) {
        let currentDay = day;
        let curDate = new Date();
        // 获取当前月份
        let curYear = curDate.getFullYear();
        let curMonth = curDate.getMonth();
        let curDay = curDate.getDate();
        console.log(curYear == year, curMonth + 1 == month);
        if (curYear == year && curMonth + 1 == month) {
          currentDay = curDay;
          this.currentSelectDay = curDay;
        }
        let date = new Date(`${year}-${month}-${currentDay}`).format(
          "yyyy-MM-dd"
        );
        this.$emit("getLessonList", date);
      }
    },
  },
};
</script>
 
<style lang = "less" scoped>
.calendar_top_icon {
  width: 12px;
  height: 12px;
  padding: 4px;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    top: 0px;
    border-color: #ffffff;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: absolute;
  }
}

.arrow_left:extend(.calendar_top_icon all) {
  &::after {
    border-width: 0 0 2px 2px;
  }
}

.arrow_right:extend(.calendar_top_icon all) {
  &::after {
    border-width: 2px 2px 0 0;
  }
}

.calendar {
  background: #a5907e;
  padding: 20px;
  padding-top: 0;

  .calendar_top {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 20px;

    .calendar_top_date {
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin: 0 60px;
    }

    .calendar_top_icon {}
  }

  .calendar_week {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
    box-sizing: border-box;

    span {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 36px;
    }
  }

  .calendar_days {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 0 20px;
    box-sizing: border-box;

    .calendar_days_item {
      width: 14.285%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 16px;
      position: relative;

      .calendar_days_item_num {
        display: block;
        width: 56px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        border-radius: 50%;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }

      .calendar_days_item_icondrop {
        position: absolute;
        bottom: 0;
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        // background: #ffffff;
      }
    }
  }

  .calendar_types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 0;
    font-size: 26px;

    .calendar_type {
      display: flex;
      align-items: center;
      color: #ffffff;

      .calendar_type_text {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .icondrop {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>