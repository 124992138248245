<template>
  <!-- 头部 -->
  <Van-Navbar :title="lang.route_home">
    <template #left>
      <img @click="toggleLanguage" :src="lang.langBtn" />
    </template>
  </Van-Navbar>
  <div>
    <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh" :loading-text="lang.loading"
      :loosing-text="lang.loosingtext" :pulling-text="lang.pullingtext">
      <!-- 月视图 -->
      <MonthCalendar ref="MonthCalendar" @getLessonList="getLessonList" :loading="loading" />
      <!-- 排课列表 -->
    </van-pull-refresh>
    <van-list class="vanlist" :loading="loading" :finished="finished" :finished-text="loading ? '' : lang.noMore"
      :loading-text="lang.loading">
      <CourseCard v-for="(item) in list" :key="item.lesson_id" :course="item" :type="'homeCourse'"
        @refreshData="refreshData"></CourseCard>
    </van-list>
  </div>
  <Van-Tabbar />
</template>

<script>
import { reactive, toRefs, ref, onActivated } from "vue";
import Common from "@/library/Common";
import request from "@/library/MyRequest";
import VanNavbar from "@/components/common/van-navbar";
import VanTabbar from "@/components/common/van-tabbar";
import CourseCard from "@/components/course/coursecard";
import MonthCalendar from "@/components/course/calendar/month";

export default {
  name: "ClassEvaluation",
  components: {
    VanNavbar,
    VanTabbar,
    CourseCard,
    MonthCalendar,
  },
  setup() {
    const MonthCalendar = ref(null);
    const common = Common;
    let state = reactive({
      lang: {
        route_home: "",
        langBtn: "",
        noMore: "",
        loading: "",
        pullingtext: "",
        loosingtext: "",
      },
      loading: false,
      refreshLoading: false,
      finished: false,
      list: [],
      currentDate: "",
    });
    common.initLanguage(state.lang);
    state.lang.langBtn = common.getLanguageHomeBtn();
    // 语言切换
    const toggleLanguage = function () {
      common.toggleLanguage();
      common.initLanguage(state.lang);
      state.lang.langBtn = common.getLanguageHomeBtn();
    };
    const getLessonList = async (date) => {
      state.currentDate = date;
      state.loading = true;
      state.refreshLoading = false;
      state.finished = false;
      state.list = [];
      let lesson = await request.get(
        `/lesson/list-lesson/?time_type=4&date=${date}`
      );
      console.log('lesson',lesson);
      setTimeout(() => {
        state.list = lesson.data.lessons;
        state.list.sort((a, b) => {
          return a['lesson_id'] - b['lesson_id']
        })
        state.loading = false;
        state.finished = true;
      }, 500);

    };
    const getNowDate = () => {
      let curDate = new Date();
      // 获取当前月份
      let curYear = curDate.getFullYear();
      let curMonth = curDate.getMonth();
      let curDay = curDate.getDate();
      getLessonList(
        `${curYear}-${curMonth + 1 < 10 ? "0" + (curMonth + 1) : curMonth + 1
        }-${curDay < 10 ? "0" + curDay : curDay}`
      );
    };
    const refreshData = (date) => {
      getLessonList(date);
      let currentdate = MonthCalendar.value.date;
      MonthCalendar.value.setMonth(
        currentdate["year"],
        currentdate["month"],
        currentdate["day"]
      );
    };
    const onRefresh = () => {
      state.refreshLoading = true;
      getLessonList(state.currentDate);
      let currentdate = MonthCalendar.value.date;
      console.log("currentdate", currentdate);
      MonthCalendar.value.setMonth(
        currentdate["year"],
        currentdate["month"],
        currentdate["day"]
      );
    };
    onActivated(() => {
      refreshData(state.currentDate);
    });
    getNowDate();
    return {
      ...toRefs(state),
      common,
      toggleLanguage,
      getLessonList,
      getNowDate,
      refreshData,
      onRefresh,
      MonthCalendar,
    };
  },
};
</script>

<style lang="less" scoped>
page {
  background: #f6f7fb;
}

.vanlist {
  background: #f6f7fb;
}
</style>